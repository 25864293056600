import * as React from 'react';
import Layout from '../components/general/layout';
import TranslateHtml from '../components/general/translateHtml';
import Container from '../components/general/container';
import NewsPageStyle from '../less/news-page.module.less';
import { graphql } from 'gatsby';

import useIntl from '../helpers/useIntl';

const Page = ({ data }) => {
  const intl = useIntl();
  const post = data.page;

  return (
    <Layout heroBgColor={'#DFECF0'}
            heroTitle={intl.translate(post, 'title')}
            heroTextColor={'#142A7F'}
            heroSize={'medium'}>
      <Container size={'small'}>
        <div className={NewsPageStyle.detail}>
          <article className={NewsPageStyle.newsInfo}>
            <TranslateHtml object={post} prop={'body'} />
          </article>
        </div>
      </Container>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query($slug: String!) {
    page(slug: { eq: $slug }) {
      id
      slug
      title_fr
      title_nl
      body_fr
      body_nl
    }
  }
`;
